
import { defineComponent, inject } from "vue";
import { Button, Col, Row, Space, CountDown } from "vant";
import PendingItem from "./step-2-checks/PendingItem.vue";
import ProcessingItem from "./step-2-checks/ProcessingItem.vue";
import SuccessItem from "./step-2-checks/SuccessItem.vue";
import FailedItem from "./step-2-checks/FailedItem.vue";
import { ServerApi } from "@/helpers/api-instance";
import { NoFallback } from "@/helpers/no-injection-fallback";

export default defineComponent({
  name: "Step2Component",
  setup() {
    return {
      api: inject<ServerApi>(ServerApi.InjectionKey, NoFallback, true),
    };
  },
  components: {
    [Button.name]: Button,
    [Space.name]: Space,
    [Col.name]: Col,
    [Row.name]: Row,
    [CountDown.name]: CountDown,
    PendingItem,
    ProcessingItem,
    SuccessItem,
    FailedItem,
  },
  props: {
    authCode: {
      type: String,
      required: true,
    },
  },
  emits: ["stage-complete", "final-token"],
  data() {
    return {
      showNextButton: false,
      nextButtonLoading: false,
      nextButtonDisabled: false,
      step1state: 0,
      step2state: 0,
      step3state: 0,
      step4state: 0,
      time: 1,
    };
  },
  mounted() {
    this.generateAccessTokenStep();
  },
  methods: {
    clickContinueButton() {
      this.nextButtonDisabled = true;
      this.nextButtonLoading = true;
      this.$emit("stage-complete");
    },
    async generateAccessTokenStep() {
      const connectRoute = this.$router.resolve("connect");
      const connectUrl = new URL(connectRoute.href, window.location.origin);
      this.step1state = 1; // Set state to processing.
      try {
        const token = await this.api.generateTokens(
          this.authCode,
          connectUrl.href
        );
        setTimeout(() => {
          this.step1state = 2; // Set state to success.
          this.checkOrganisationAccess(token);
        }, 1000);
      } catch (e) {
        this.step1state = 3; // Set state to failed.
        this.nextButtonDisabled = true;
        this.showNextButton = true;
      }
    },
    async checkOrganisationAccess(previousStageToken: string) {
      this.step2state = 1; // Set state to processing.
      try {
        const token = await this.api.checkAccess(
          previousStageToken,
          "organisation"
        );
        setTimeout(() => {
          this.step2state = 2; // Set state to success.
          this.checkEmployeeListAccess(token);
        }, 1000);
      } catch (e) {
        this.step2state = 3; // Set state to failed.
        this.nextButtonDisabled = true;
        this.showNextButton = true;
      }
    },
    async checkEmployeeListAccess(previousStageToken: string) {
      this.step3state = 1; // Set state to processing.
      try {
        const token = await this.api.checkAccess(
          previousStageToken,
          "employee-list"
        );
        setTimeout(() => {
          this.step3state = 2; // Set state to success.
          this.checkCertificationsListAccess(token);
        }, 1000);
      } catch (e) {
        this.step3state = 3; // Set state to failed.
        this.nextButtonDisabled = true;
        this.showNextButton = true;
      }
    },
    async checkCertificationsListAccess(previousStageToken: string) {
      this.step4state = 1; // Set state to processing.
      try {
        const token = await this.api.checkAccess(
          previousStageToken,
          "certifications-list"
        );
        setTimeout(() => {
          this.step4state = 2; // Set state to success.
          this.$emit("final-token", token);
          this.time = 10 * 60 * 1000 - 1000;
          this.showNextButton = true;
        }, 1000);
      } catch (e) {
        this.step4state = 3; // Set state to failed.
        this.nextButtonDisabled = true;
        this.showNextButton = true;
      }
    },
  },
});
