import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_step = _resolveComponent("van-step")!
  const _component_van_steps = _resolveComponent("van-steps")!

  return (_openBlock(), _createBlock(_component_van_steps, {
    active: _ctx.step,
    class: "vstepper",
    "active-icon": "arrow",
    direction: _ctx.stepperDirection
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_step, null, {
        default: _withCtx(() => [
          _createTextVNode("1. Begin Refresh")
        ]),
        _: 1
      }),
      _createVNode(_component_van_step, null, {
        default: _withCtx(() => [
          _createTextVNode("2. Connect to Employment Hero")
        ]),
        _: 1
      }),
      _createVNode(_component_van_step, null, {
        default: _withCtx(() => [
          _createTextVNode("3. Generate and Validate Token")
        ]),
        _: 1
      }),
      _createVNode(_component_van_step, null, {
        default: _withCtx(() => [
          _createTextVNode("4. Update Campfire")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["active", "direction"]))
}