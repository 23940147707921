import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-471a5fc9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-progress" }
const _hoisted_2 = { class: "action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_stepper = _resolveComponent("progress-stepper")!
  const _component_Step0Component = _resolveComponent("Step0Component")!
  const _component_Step1Component = _resolveComponent("Step1Component")!
  const _component_Step2Component = _resolveComponent("Step2Component")!
  const _component_Step3Component = _resolveComponent("Step3Component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_progress_stepper, { step: _ctx.step }, null, 8, ["step"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.step === 0)
        ? (_openBlock(), _createBlock(_component_Step0Component, {
            key: 0,
            onStageComplete: _ctx.incrementStage
          }, null, 8, ["onStageComplete"]))
        : _createCommentVNode("", true),
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_component_Step1Component, {
            key: 1,
            onStageComplete: _ctx.incrementStage,
            onAuthCode: _ctx.receiveAuthCode
          }, null, 8, ["onStageComplete", "onAuthCode"]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createBlock(_component_Step2Component, {
            key: 2,
            onStageComplete: _ctx.incrementStage,
            onFinalToken: _ctx.receiveUpdateToken,
            authCode: _ctx.authCode ?? ''
          }, null, 8, ["onStageComplete", "onFinalToken", "authCode"]))
        : _createCommentVNode("", true),
      (_ctx.step === 3)
        ? (_openBlock(), _createBlock(_component_Step3Component, {
            key: 3,
            updateToken: _ctx.updateToken ?? ''
          }, null, 8, ["updateToken"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}