
import { defineComponent, inject } from "vue";
import { Col, Row, Button, Loading } from "vant";
import { ServerApi } from "@/helpers/api-instance";
import { NoFallback } from "@/helpers/no-injection-fallback";

export default defineComponent({
  name: "Step3Component",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    ["van-loading"]: Loading,
  },
  setup() {
    return {
      api: inject<ServerApi>(ServerApi.InjectionKey, NoFallback, true),
    };
  },
  props: {
    updateToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: 0,
    };
  },
  mounted() {
    this.updateCampfireTokens();
  },
  methods: {
    async updateCampfireTokens() {
      try {
        await this.api.updateCampfire(this.updateToken);
        setTimeout(() => {
          this.status = 1; // Set state to success.
        }, 1000);
      } catch (e) {
        this.status = 2; // Set state to failed.
      }
    },
  },
});
