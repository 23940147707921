
import { defineComponent } from "vue";
import { Button, Row, Col } from "vant";

export default defineComponent({
  name: "Step0Component",
  //props: {}, //TODO: Add JWT prop and await server validation.
  emits: ["stage-complete"],
  components: {
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
  },
  data() {
    return {
      nextButtonDisabled: false,
      nextButtonLoading: false,
    };
  },
  methods: {
    clickContinueButton() {
      this.nextButtonDisabled = true;
      this.nextButtonLoading = true;
      this.$emit("stage-complete");
    },
  },
});
