import { InjectionKey } from "vue";
import axios, { AxiosInstance } from "axios";

export class ServerApi {
  public static InjectionKey: InjectionKey<ServerApi> = Symbol();

  private basePath: string;
  private connection: AxiosInstance;

  constructor() {
    this.basePath = process.env.VUE_APP_API_PATH;
    this.connection = axios.create({
      baseURL: this.basePath,
    });
  }

  public async generateTokens(authCode: string, callbackUrl: string) {
    const result = await this.connection.post("/generate", {
      authCode,
      callbackUrl,
    });

    return result.data;
  }

  public async checkAccess(
    previousStageToken: string,
    access:
      | "organisation"
      | "employee-list"
      | "certifications-list"
      | "employee-certifications"
  ) {
    const result = await this.connection.post(`/check-access/${access}`, {
      previousStageToken,
    });

    return result.data;
  }

  public async updateCampfire(previousStageToken: string) {
    const result = await this.connection.post(`/update-campfire`, {
      previousStageToken,
    });

    return result.data;
  }
}
