
import { defineComponent } from "vue";
import ProgressStepper from "@/components/ProgressStepper.vue";
import Step0Component from "@/components/Step0.vue";
import Step1Component from "@/components/Step1.vue";
import Step2Component from "@/components/Step2.vue";
import Step3Component from "@/components/Step3.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    ProgressStepper,
    Step0Component,
    Step1Component,
    Step2Component,
    Step3Component,
  },
  data() {
    return {
      step: 0,
      authCode: null as string | null,
      updateToken: null as string | null,
    };
  },
  methods: {
    incrementStage() {
      this.step += 1;
    },
    receiveAuthCode(code: string) {
      this.authCode = code;
    },
    receiveUpdateToken(token: string) {
      this.updateToken = token;
    },
  },
});
