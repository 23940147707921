
import { Button, Col, Row } from "vant";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Step1Component",
  emits: ["stage-complete", "auth-code"],
  components: {
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
  },
  data() {
    return {
      authCode: null as string | null,
      nextButtonDisabled: false,
      nextButtonLoading: false,
    };
  },
  computed: {
    authenticationUrl() {
      const connectRoute = this.$router.resolve("connect");
      const connectUrl = new URL(connectRoute.href, window.location.origin);
      const params = new URLSearchParams({
        client_id: process.env.VUE_APP_EH_CLIENT_ID,
        redirect_uri: connectUrl.href,
        response_type: "code",
      });

      return `https://oauth.employmenthero.com/oauth2/authorize?${params.toString()}`;
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
  },
  methods: {
    clickContinueButton() {
      this.nextButtonDisabled = true;
      this.nextButtonLoading = true;
      this.$emit("stage-complete");
    },
    receiveMessage(event: MessageEvent) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (!("type" in event.data)) {
        return;
      }
      if (event.data.type !== "f5_portal_pass_code") {
        return;
      }
      this.authCode = event.data.code;
      event.source?.postMessage({ type: "f5_portal_receive_code" });
      this.$emit("auth-code", event.data.code);
    },
    openAuthenticationWindow() {
      const features = {
        popup: "yes",
        width: 600,
        height: 700,
        top: "auto",
        left: "auto",
        toolbar: "no",
        menubar: "no",
      };
      const strWindowsFeatures = Object.entries(features)
        .reduce((str, [key, value]) => {
          if (value == "auto") {
            if (key === "top") {
              const v = Math.round(
                window.innerHeight / 2 - features.height / 2
              );
              str += `top=${v},`;
            } else if (key === "left") {
              const v = Math.round(window.innerWidth / 2 - features.width / 2);
              str += `left=${v},`;
            }
            return str;
          }

          str += `${key}=${value},`;
          return str;
        }, "")
        .slice(0, -1);

      window.open(this.authenticationUrl, "_blank", strWindowsFeatures);
    },
  },
});
