
import { defineComponent } from "vue";
import { Loading } from "vant";

export default defineComponent({
  name: "ProcessingItem",
  components: {
    ["van-loading"]: Loading,
  },
  props: {
    caption: String,
  },
});
