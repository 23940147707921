
import { defineComponent } from "vue";
import { Step, Steps } from "vant";

export default defineComponent({
  name: "ProgressStepper",
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  onBeforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  computed: {
    stepperDirection() {
      if (this.windowWidth < 940) {
        return "vertical";
      } else {
        return "horizontal";
      }
    },
  },

  props: {
    step: Number,
  },
});
