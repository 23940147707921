import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "EnableWA Token Refresher",
    },
  },
  {
    path: "/connect",
    name: "connect",
    // route level code-splitting
    // this generates a separate chunk (connect.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "connect" */ "../views/ConnectView.vue"),

    meta: {
      title: "EnableWA Token Refresher - Authorizing...",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    // route level code-splitting
    // this generates a separate chunk (notfound.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),

    meta: {
      title: "EnableWA Token Refresher - Page Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  const title = to.meta.title as string;

  if (title) {
    document.title = title;
  }
});

export default router;
